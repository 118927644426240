<template>
  <!-- start 考勤状态时间对话框 -->
  <el-dialog
    title="员工打卡记录"
    :visible.sync="showDialog"
    width="55%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <div class="df-row">
        <!-- 日期选择器 -->
        <div class="df-col">
          <el-date-picker
            style="width: 250px;"
            v-model="dayArray"
            type="daterange"
            size="mini"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="onChangeDate"
          >
          </el-date-picker>
        </div>

        <div class="df-col" style="width: 120px;">
          <!-- 职位选择器 -->
          <el-select
            v-model="submitData.duty_no"
            size="mini"
            @change="handleLevelAndEmpl(1)"
          >
            <el-option
              v-for="item in dutyList"
              :key="item.DutyNo"
              :label="item.DutyName"
              :value="item.DutyNo"
            >
            </el-option>
          </el-select>
        </div>

        <div class="df-col" style="width: 140px;">
          <!-- 级别选择器 -->
          <el-select
            v-model="submitData.level_guid"
            size="mini"
            @change="handleLevelAndEmpl(2)"
          >
            <el-option
              v-for="item in levelList"
              :key="item.LevelGuid"
              :label="item.LevelName"
              :value="item.LevelGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div class="df-col" style="width: 120px;">
          <!-- 员工选择器 -->
          <el-select
            v-model="submitData.empl_guid"
            size="mini"
            @change="handleLevelAndEmpl(3)"
          >
            <el-option
              v-for="(item, index) in emplList"
              :key="item.EmplGuid"
              :label="index == 0 ? item.EmplName : `${item.EmplNo}_${item.EmplName}`"
              :value="item.EmplGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div class="df-col">
          <!-- 查询按钮 -->
          <el-button type="primary" size="mini" @click="onSearch">
            查询
          </el-button>
        </div>
      </div>

      <el-table
        id="exprot-table"
        size="mini"
        :data="punchList"
        max-height="400px"
        border
        stripe
      >
        <el-table-column prop="DutyName" label="职位"> </el-table-column>
        <el-table-column prop="LevelName" label="级别"> </el-table-column>
        <el-table-column prop="CardNo" label="卡号" width="100"> </el-table-column>
        <el-table-column prop="EmplNo" label="工号"> </el-table-column>
        <el-table-column prop="EmplName" label="姓名"> </el-table-column>
        <el-table-column prop="AtteClassName" label="类型"> </el-table-column>

        <el-table-column label="打卡时间" width="140">
            <template slot-scope="scoped">{{ util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(scoped.row.AtteTime * 1000)) }}</template>
        </el-table-column>
        <el-table-column prop="AtteStateName" label="状态"> </el-table-column>

        <el-table-column prop="StateTime" label="计时(分)"> </el-table-column>
        <el-table-column prop="StateNote" label="说明"> </el-table-column>

        <el-table-column prop="ApprName" label="批准人"> </el-table-column>
      </el-table>
    </div>

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCloseDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="onSubmitData"
        >确 定</el-button
      >
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 考勤状态时间对话框 -->
</template>

<script>
import Attendance from "@/api/attendance.js";

export default {
  data() {
    return {
      dayArray: [],
      dutyList: [],
      copyLevelList: [],
      copyEmplList: [],
      levelList: [],
      emplList: [],
      punchList: [],
      submitData: {
        // 提交数据
        begin_date: "",
        end_date: "",
        duty_no: "",
        level_guid: "",
        empl_guid: "",
      },
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 获取打开时间明细（打卡记录）
    async getAttePunchList() {
      try {
        let { data } = await Attendance.getAttePunchList(this.submitData);

        this.punchList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.getAttePunchList();
    },

    // 级别列表和员工列表处理事件
    handleLevelAndEmpl(changeType = 0) {
      let copyEmplList = JSON.parse(JSON.stringify(this.copyEmplList)),
        copyLevelList = JSON.parse(JSON.stringify(this.copyLevelList));
      let { duty_no, level_guid, empl_guid } = this.submitData;

      if (changeType == 1) {
        level_guid = "";
        empl_guid = "";
      } else if (changeType == 2) {
        empl_guid = "";
      }

      let levelArr = !duty_no
        ? copyLevelList
        : copyLevelList.filter((item, index) => {
            if (index == 0 || item.DutyNo == duty_no) {
              return item;
            }
          });

      let emplArr = !duty_no
        ? copyEmplList
        : copyEmplList.filter((item, index) => {
            if (index == 0 || item.LevelGuid == level_guid) {
              return item;
            }
          });

      this.submitData.level_guid = level_guid;
      this.submitData.empl_guid = empl_guid;

      this.levelList = levelArr;
      this.emplList = emplArr;
    },

    // 重置多选框表头数据
    resetCustomData() {
      this.heads = [];
      this.headTops = [];
    },

    // 时间改变事件
    onChangeDate(event) {
      this.submitData.begin_date = event[0];
      this.submitData.end_date = event[1];
    },

    // 打开对话框
    async onShowDialog(dataArr, submitData) {
      this.dutyList = dataArr.dutyList;
      this.dayArray = [submitData.begin_date, submitData.end_date];
      this.copyLevelList = dataArr.levelList;
      this.copyEmplList = dataArr.emplList;
      this.submitData = submitData;

      await this.$nextTick(() => {
        this.showDialog = true;
        this.handleLevelAndEmpl();
        this.getAttePunchList();
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 确定按钮点击事件
    async onSubmitData() {},
  },
};
</script>

<style lang="less" scoped></style>
