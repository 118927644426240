import { request } from "../utils/axios.js";

export default {
  // 员工 ===> 考勤-明细表

  // 初始化考勤明细
  initAtteQuery(params = {}) {
    return request("GET", "/attendance/init_atte_query");
  },

  // 获取考勤明细
  getAtteDetailList(params = {}) {
    return request("GET", "/attendance/get_atte_detail_list", params);
  },

  // 获取打卡时间明细
  getAttePunchList(params = {}) {
    return request("GET", "/attendance/get_atte_punch_list", params);
  },

  // 获取考勤状态时间
  getAtteStateTimes(params = {}) {
    return request("GET", "/attendance/get_atte_state_times", params);
  },

  // 初始化考勤状态时间
  initStateTime(params = {}) {
    return request("GET", "/attendance/init_state_time", params);
  },

  // 保存考勤状态时间
  saveStateTime(params = {}) {
    return request("POST", "/attendance/save_state_time", params);
  },

  // 导入打卡记录
  importPunchRecord(params = {}) {
    return request("GET", "/attendance/import_punch_record", params);
  },

  // 删除考勤明细
  deleteAtteDetail(params = {}) {
    return request("GET", "/attendance/delete_atte_detail", params);
  },

  // 取消审核
  cancelCheckMark(params = {}) {
    return request("POST", "/attendance/cancel_check_mark", params);
  },

  // 员工 ===> 考勤-统计表

  // 获取考勤统计数据
  getStatisData(params = {}) {
    return request("GET", "/attendance/get_statis_data", params);
  },
};
