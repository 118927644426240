<template>
  <div class="work-detail">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          style="width: 250px;"
          v-model="dayArray"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col" style="width: 120px;">
        <!-- 职位选择器 -->
        <el-select
          v-model="submitData.duty_no"
          size="mini"
          @change="handleLevelAndEmpl(1)"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col" style="width: 140px;">
        <!-- 级别选择器 -->
        <el-select
          v-model="submitData.level_guid"
          size="mini"
          @change="handleLevelAndEmpl(2)"
        >
          <el-option
            v-for="item in levelList"
            :key="item.LevelGuid"
            :label="item.LevelName"
            :value="item.LevelGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col" style="width: 120px;">
        <!-- 员工选择器 -->
        <el-select
          v-model="submitData.empl_guid"
          size="mini"
          @change="handleLevelAndEmpl(3)"
        >
          <el-option
            v-for="item in emplList"
            :key="item.EmplGuid"
            :label="item.EmplName"
            :value="item.EmplGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col" style="width: 100px;">
        <!-- 状态类别选择器 -->
        <el-select
          v-model="submitData.check_mark"
          size="mini"
          placeholder="不限"
        >
          <el-option
            v-for="item in [
              { name: '全部', value: 0 },
              { name: '未审核', value: 1 },
              { name: '已审核', value: 2 },
            ]"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>

      <div class="df-col">
        <!-- 明细按钮 -->
        <el-button type="primary" size="mini" @click="onShowPunch({})">
          查看打卡记录
        </el-button>
      </div>

      <div class="df-col">
        <!-- 导入记录按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="importDisabled"
          @click="importPunchRecord"
        >
          导入打卡记录
        </el-button>
      </div>

      <!-- 删除考勤按钮 -->
      <div class="df-col">
        <el-button
          type="danger"
          size="mini"
          :disabled="deleteDisabled"
          @click="deleteAtteDetail"
        >
          删除考勤明细
        </el-button>
      </div>

      <!-- 取消审核按钮 -->
      <div class="df-col">
        <el-button
          type="danger"
          size="mini"
          :disabled="reviewDisabled"
          @click="cancelCheckMark"
        >
          取消审核
        </el-button>
      </div>

      <!-- 导出表格按钮 -->
      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <div class="print">
      <el-table
        id="exprot-table"
        size="mini"
        ref="atteTable"
        :data="attendanceList"
        :max-height="tableMaxHeight"
        @select="onChangeSelect"
        @select-all="onChangeSelectAll"
        border
        stripe
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="DutyName" label="职位"> </el-table-column>
        <el-table-column prop="LevelName" label="级别"> </el-table-column>
        <el-table-column prop="EmplNo" label="工号"> </el-table-column>
        <el-table-column prop="EmplName" label="姓名"> </el-table-column>
        <el-table-column label="日期" width="100">
          <template slot-scope="scoped">{{
            util.dateFormat("YYYY-mm-dd", new Date(scoped.row.AtteDate * 1000))
          }}</template>
        </el-table-column>
        <el-table-column prop="SchedStateName" label="状态"></el-table-column>

        <el-table-column prop="ShiftName" label="班次名称"> </el-table-column>
        <el-table-column prop="ShiftTypeName" label="班次类型">
        </el-table-column>

        <el-table-column label="上班时间_1" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.Work_1
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.Work_1 * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="下班时间_1" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.OffWork_1
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.OffWork_1 * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="上班时间_2" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.Work_2
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.Work_2 * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="下班时间_2" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.OffWork_2
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.OffWork_2 * 1000)
                )
          }}</template>
        </el-table-column>

        <el-table-column label="上班时间_3" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.Work_3
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.Work_3 * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="下班时间_3" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.OffWork_3
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.OffWork_3 * 1000)
                )
          }}</template>
        </el-table-column>

        <el-table-column label="审核标记">
          <template slot-scope="scoped">{{
            scoped.row.CheckMark == 2 ? "已审核" : "未审核"
          }}</template>
        </el-table-column>
        <el-table-column prop="" label="审核时间" width="140">
          <template slot-scope="scoped">{{
            !scoped.row.CheckTime
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.CheckTime * 1000)
                )
          }}</template>
        </el-table-column>

        <el-table-column label="操作" width="220" :fixed="isFixedRow">
          <template slot-scope="scoped">
            <el-button
              type="primary"
              size="mini"
              @click="onShowStatus(scoped.row)"
              >状态明细</el-button
            >

            <!-- 明细按钮 -->
            <el-button
              type="success"
              size="mini"
              @click="onShowPunch(scoped.row)"
            >
              打卡明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- 考勤状态时间表格对话框 -->
    <DetailStatusDialog
      ref="detailStatusDialog"
      @onSelected="onShowEdit"
    ></DetailStatusDialog>

    <!-- 修改考勤状态对话框 -->
    <DetailEditDialog
      ref="detailEditDialog"
      @success="saveSuccess"
    ></DetailEditDialog>

    <!-- 打卡时间表格对话框 -->
    <DetailPunchDialog ref="detailPunchDialog"></DetailPunchDialog>
  </div>
</template>

<script>
import DetailStatusDialog from "./components/detail-status-dialog.vue";
import DetailEditDialog from "./components/detail-edit-dialog.vue";
import DetailPunchDialog from "./components/detail-punch-dialog.vue";

import Attendance from "@/api/attendance.js";

export default {
  components: { DetailStatusDialog, DetailEditDialog, DetailPunchDialog },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      isCheckCount: 0, // 多选框可以正常被选中的数量
      totalRecord: 0, // 总数
      submitData: {
        // 提交数据
        page_index: 1,
        page_size: 20,
        begin_date: "",
        end_date: "",
        duty_no: "",
        level_guid: "",
        empl_guid: "",
        check_mark: 0,
      },
      dayArray: [], // 日期范围选择数据
      dutyList: [], // 职位列表
      copyEmplList: [], // 级别列表(源数据)
      copyLevelList: [], // 员工列表(源数据)
      levelList: [], // 级别列表(回显数据)
      emplList: [], // 员工列表(回显数据)
      attendanceList: [], // 考勤统计列表
      attenGuids: [], // 考勤id
      selectedItem: {}, // 最后一次选中考勤信息数据
      isAllCheck: false,
      isFixedRow: "right", // 表格单元格定位
      excelDisabled: false, // 导出防抖
      importDisabled: false, // 导入防抖
      deleteDisabled: false, // 删除防抖
      reviewDisabled: false, // 审核防抖
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.dayArray = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate(this.dayArray);

    this.initAtteQuery();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化考勤明细
    async initAtteQuery() {
      try {
        let { data } = await Attendance.initAtteQuery();
        let { dutyList, emplList, levelList } = data;

        this.dutyList = [{ DutyName: "全部职位", DutyNo: "" }, ...dutyList];
        this.copyEmplList = [
          { EmplName: "全部员工", EmplGuid: "", EmplNo: "", LevelGuid: "" },
          ...emplList,
        ];
        this.copyLevelList = [
          { LevelName: "全部级别", LevelGuid: "", DutyNo: "" },
          ...levelList,
        ];

        await this.$nextTick(() => {
          this.handleLevelAndEmpl();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // 获取考勤明细
    async getAtteDetailList() {
      try {
        let { data } = await Attendance.getAtteDetailList(this.submitData);
        let arr = data.list.filter((item) => item.CheckMark == 2);

        this.totalRecord = data.filter.TotalRecord;
        this.attendanceList = data.list;

        this.isCheckCount = arr.length || 0;
      } catch (err) {
        console.log(err);
      }
    },

    // 导入打卡记录
    importPunchRecord() {
      let submitData = {
        begin_date: this.submitData.begin_date,
        end_date: this.submitData.end_date,
      };

      this.$confirm(
        `您确定要导入【时间: ${submitData.begin_date} 至 ${submitData.end_date} 】的考勤明细吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        this.importDisabled = true;
        try {
          let { errcode } = await Attendance.importPunchRecord(submitData);
          if (errcode == 0) {
            this.$message.success("考勤明细导入成功");
            this.getAtteDetailList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.importDisabled = false;
        }
      });
    },

    // 删除考勤明细
    deleteAtteDetail() {
      this.$confirm(
        `您确定要删除时间【${this.submitData.begin_date} 至 ${this.submitData.end_date}】中的考勤明细吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        this.deleteDisabled = true;
        let submitData = {
          begin_date: this.submitData.begin_date,
          end_date: this.submitData.end_date,
        };
        try {
          let { errcode } = await Attendance.deleteAtteDetail(submitData);
          if (errcode == 0) {
            this.$message.success("考勤明细删除成功");
            this.getAtteDetailList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false;
        }
      });
    },

    // 取消审核
    cancelCheckMark() {
      if (!this.attenGuids.length) {
        return this.$message.info("请选择您要取消的打卡记录");
      }

      this.$confirm(`您确定要取消当前所选考勤明细的审核吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.reviewDisabled = true;
        try {
          let { errcode } = await Attendance.cancelCheckMark({
            AtteGuids: this.attenGuids,
          });
          if (errcode == 0) {
            this.$message.success("取消审核成功");
            this.getAtteDetailList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.reviewDisabled = false;
        }
      });
    },

    // 修改考勤状态时间成功回调
    saveSuccess() {
      this.$refs.detailStatusDialog.onCloseDialog();
      this.getAtteDetailList();
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 72 + 52);
    },

    // 级别列表和员工列表处理事件
    handleLevelAndEmpl(changeType) {
      let copyEmplList = JSON.parse(JSON.stringify(this.copyEmplList)),
        copyLevelList = JSON.parse(JSON.stringify(this.copyLevelList));
      let { duty_no, level_guid, empl_guid } = this.submitData;

      if (changeType == 1) {
        level_guid = "";
        empl_guid = "";
      } else if (changeType == 2) {
        empl_guid = "";
      }

      let levelArr = !duty_no
        ? copyLevelList
        : copyLevelList.filter((item, index) => {
            if (index == 0 || item.DutyNo == duty_no) {
              return item;
            }
          });

      let emplArr = !duty_no
        ? copyEmplList
        : copyEmplList.filter((item, index) => {
            if (index == 0 || item.LevelGuid == level_guid) {
              return item;
            }
          });

      this.submitData.level_guid = level_guid;
      this.submitData.empl_guid = empl_guid;

      this.levelList = levelArr;
      this.emplList = emplArr;
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getAtteDetailList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getAtteDetailList();
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getAtteDetailList();
    },

    // 时间改变事件
    onChangeDate(event) {
      this.submitData.begin_date = event[0];
      this.submitData.end_date = event[1];
    },

    // 多选框全选点击事件
    onChangeSelectAll(event) {
      let arr = [];

      if (this.isAllCheck) {
        this.$refs.atteTable.clearSelection();
      } else {
        this.attendanceList.forEach((item) => {
          // console.log(item);
          if (item.CheckMark == 2) {
            arr.push(item.AtteGuid);
            this.$refs.atteTable.toggleRowSelection(item, true);
          } else {
            this.$refs.atteTable.toggleRowSelection(item, false);
          }
        });
      }

      // console.log(event);
      this.isAllCheck = !this.isAllCheck;
      this.attenGuids = arr;
      // console.log(this.attenGuids);
    },

    // 多选框改变事件
    onChangeSelect(event, row) {
      let { attenGuids } = this;

      let index = attenGuids.indexOf(row.AtteGuid);
      if (row.CheckMark == 1) {
        // event.splice(index, 1)
        this.$refs.atteTable.toggleRowSelection(row, false);
        return this.$message.info("当前考勤明细尚未审核，无可操作");
      }

      index > -1 ? attenGuids.splice(index, 1) : attenGuids.push(row.AtteGuid);

      this.isAllCheck = attenGuids.length == this.isCheckCount ? true : false;
      // console.log(this.attenGuids);
    },

    // 打开考勤状态时间对话框
    onShowStatus(event) {
      // console.log(event);
      this.$refs.detailStatusDialog.onShowDialog(event);
    },

    // 打开修改对话框
    onShowEdit(event) {
      this.$refs.detailEditDialog.onShowDialog(event);
    },

    // 打开打卡记录
    onShowPunch(event) {
      // if (!this.selectedItem.AtteDate) {
      //   return this.$message.info("请勾选一条考勤记录");
      // }

      let beginDate = !event.AtteDate
          ? this.submitData.begin_date
          : this.util.dateFormat("YYYY-mm-dd", new Date(event.AtteDate * 1000)),
        endDate = !event.AtteDate
          ? this.submitData.end_date
          : this.util.dateFormat("YYYY-mm-dd", new Date(event.AtteDate * 1000));

      let dataArr = {
        dutyList: this.dutyList,
        levelList: this.copyLevelList,
        emplList: this.copyEmplList,
      };

      let submitData = {
        begin_date: beginDate,
        end_date: endDate,
        duty_no: this.submitData.duty_no || "",
        level_guid: this.submitData.level_guid || "",
        empl_guid: event.EmplGuid || "",
      };
      // console.log(event);
      this.$refs.detailPunchDialog.onShowDialog(dataArr, submitData);
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "员工考勤明细表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = "right";
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.work-detail {
  .df-row {
    margin-top: 20px;
  }
}
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
