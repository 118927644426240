<template>
  <!-- start 考勤状态时间对话框 -->
  <el-dialog
    title="考勤状态时间"
    :visible.sync="showDialog"
    width="55%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <el-table
        id="exprot-table"
        size="mini"
        :data="stateList"
        max-height="300px"
        border
        stripe
      >
        <el-table-column label="时间段" prop="TimeSlotName"></el-table-column>
        <el-table-column label="开始时间" width="130">
          <template slot-scope="scoped">{{
            !scoped.row.AtteWork
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.AtteWork * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="结束时间" width="130">
          <template slot-scope="scoped">{{
            !scoped.row.AtteOffWork
              ? ""
              : util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(scoped.row.AtteOffWork * 1000)
                )
          }}</template>
        </el-table-column>
        <el-table-column label="状态_1" prop="State_1Name"></el-table-column>
        <el-table-column label="计时_1" prop="Time_1"></el-table-column>
        <el-table-column label="状态_2" prop="State_2Name"></el-table-column>
        <el-table-column label="计时_2" prop="Time_2"></el-table-column>
        <el-table-column label="状态_3" prop="State_3Name"></el-table-column>
        <el-table-column label="计时_3" prop="Time_3"></el-table-column>
        <el-table-column label="批准人" prop="ApprName"></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scoped">
            <el-button
              type="primary"
              size="mini"
              @click="onShowEdit(scoped.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCloseDialog">取 消</el-button>
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 考勤状态时间对话框 -->
</template>

<script>
import Attendance from "@/api/attendance.js";

export default {
  data() {
    return {
      // atteGuid: "",
      atteInfo: {},
      stateList: [], // 
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 获取考勤状态时间
    async getAtteStateTimes() {
      try {
        let { data } = await Attendance.getAtteStateTimes({
          atte_guid: this.atteInfo.AtteGuid,
        });
        this.stateList = data
      } catch (err) {
        console.log(err);
      }
    },

    // 重置多选框表头数据
    resetCustomData() {
      this.heads = [];
      this.headTops = [];
    },

    // 打开对话框
    async onShowDialog(event) {
      // console.log(event);
      this.atteInfo = event
      // this.atteGuid = event.AtteGuid;
      this.getAtteStateTimes()
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 修改按钮点击事件
    onShowEdit(event) {
      this.$emit("onSelected", {stateInfo: event, empl: `${this.atteInfo.EmplNo}_${this.atteInfo.EmplName}`});
    },
  },
};
</script>

<style lang="less" scoped></style>
