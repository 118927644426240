<template>
  <!-- start 考勤状态时间对话框 -->
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <el-form :model="stateInfo" ref="form" label-width="80px" size="mini">
        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="时间段">
              <!-- <el-input></el-input> -->
              {{ stateInfo.timeSlotName }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="考勤日期">
              <!-- <el-input v-model="stateInfo.AtteDate"></el-input> -->
              {{
                !stateInfo.AtteDate ? '' : util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(stateInfo.AtteDate * 1000)
                )
              }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="开始时间">
              <!-- <el-input></el-input> -->
              {{
                !stateInfo.AtteWork ? '' : util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(stateInfo.AtteWork * 1000)
                )
              }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="结束时间">
              {{
                !stateInfo.AtteOffWork ? '' : util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(stateInfo.AtteOffWork * 1000)
                )
              }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="状态_1">
              <el-select
                v-model="stateInfo.State_1"
                placeholder="请选择"
                clearable
                @change=""
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.StateNo"
                  :label="item.StateName"
                  :value="item.StateNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="时间_1">
              <el-input v-model="stateInfo.Time_1"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="状态_2">
              <el-select
                v-model="stateInfo.State_2"
                placeholder="请选择"
                clearable
                @change=""
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.StateNo"
                  :label="item.StateName"
                  :value="item.StateNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="时间_2">
              <el-input v-model="stateInfo.Time_2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="状态_3">
              <el-select
                v-model="stateInfo.State_3"
                placeholder="请选择"
                clearable
                @change=""
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.StateNo"
                  :label="item.StateName"
                  :value="item.StateNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="时间_3">
              <el-input v-model="stateInfo.Time_3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowSpan">
          <el-col :span="colSpan">
            <el-form-item label="批准人">
              <el-select
                v-model="stateInfo.ApprGuid"
                placeholder="请选择"
                clearable
                :disabled="stateInfo.TimeSlot != 5"
                @change=""
              >
                <el-option
                  v-for="item in apprList"
                  :key="item.EmplGuid"
                  :label="item.EmplName"
                  :value="item.EmplGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCloseDialog">取 消</el-button>
      <el-button size="small" type="primary" :disabled="submitDisabled" @click="saveStateTime"
        >确 定</el-button
      >
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 考勤状态时间对话框 -->
</template>

<script>
import Attendance from "@/api/attendance.js";

export default {
  data() {
    return {
      rowSpan: 20,
      colSpan: 11,
      title: "",
      stateGuid: "", // 考勤状态id
      stateList: [], // 打卡状态列表
      apprList: [], // 批准人列表
      stateInfo: {}, // 考勤状态时间
      showDialog: false, // 对话框显示
      submitDisabled: false,  // 提交防抖
    };
  },

  methods: {
    // 初始化考勤状态时间
    async initStateTime() {
      try {
        let { data } = await Attendance.initStateTime({
          state_guid: this.stateGuid,
        });
        this.stateInfo = {...data.stateInfo, timeSlotName: data.timeSlotName};
        this.stateList = data.stateList;
        this.apprList = data.apprList;
      } catch (err) {
        console.log(err);
      }
    },

    async saveStateTime(){
      let { stateInfo, stateGuid } = this
      this.submitDisabled = true

      let submitData = {
        StateGuid: stateGuid,
        State1: stateInfo.State_1,
        State2: stateInfo.State_2,
        State3: stateInfo.State_3,
        Time1: stateInfo.Time_1,
        Time2: stateInfo.Time_2,
        Time3: stateInfo.Time_3,
      }
      try {
        let { errcode } = await Attendance.saveStateTime(submitData)
        if(errcode == 0){
          this.$message.success('保存成功')
          this.onCloseDialog()
          this.$emit('success');
        }
      } catch (err) {
        console.log(err);
      } finally{
        this.submitDisabled = false
      }
    },

    // 重置多选框表头数据
    resetCustomData() {
      this.heads = [];
      this.headTops = [];
    },

    // 打开对话框
    async onShowDialog({ stateInfo, empl }) {
      this.stateGuid = stateInfo.StateGuid;
      this.title = `修改【${empl}】状态时间`;
      await this.$nextTick(() => {
        this.initStateTime();
        this.showDialog = true;
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    }
  },
};
</script>

<style lang="less" scoped></style>
